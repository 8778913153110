@use '../abstracts/variables'as v;
body {
    background-color: #F4F4F4;
}
html, body {
    height: 100%;
    margin: 0;
}
button:focus, input:focus, textarea:focus {
  outline: none;
}
p,
span,
H1,
H2,
H3,
H5,
H6,
h1,
h2,
h3,
h4,
h5,
h6,
small,
button,
div,
th,
td,
label,
input,
textarea {
  margin: 0;
  outline: none;
  resize: none;
  font-weight: 400;
  color: v.$base-font-color;
}

th {
  text-align: inherit;
}

table {
  border-collapse: collapse;
}

a, a:-webkit-any-link {
  text-decoration: none;
}

input:not([type='radio']), textarea {
  -webkit-appearance: none;
  line-height: normal;
}