/* Color */
/* Box Shadow */
/* Border */
/* Filter */
/* Components */
/* @media */
/* Default Break */
/* Font Break */
/* Layout Break */
/* Color */
/* Box Shadow */
/* Border */
/* Filter */
/* Components */
/* @media */
/* Default Break */
/* Font Break */
/* Layout Break */
/* Color */
/* Box Shadow */
/* Border */
/* Filter */
/* Components */
/* @media */
/* Default Break */
/* Font Break */
/* Layout Break */
@import "../assets/fonts/Roboto/Flex.css";
body {
  background-color: #F4F4F4;
}

html, body {
  height: 100%;
  margin: 0;
}

button:focus, input:focus, textarea:focus {
  outline: none;
}

p,
span,
H1,
H2,
H3,
H5,
H6,
h1,
h2,
h3,
h4,
h5,
h6,
small,
button,
div,
th,
td,
label,
input,
textarea {
  margin: 0;
  outline: none;
  resize: none;
  font-weight: 400;
  color: var(--base-font-color);
}

th {
  text-align: inherit;
}

table {
  border-collapse: collapse;
}

a, a:-webkit-any-link {
  text-decoration: none;
}

input:not([type=radio]), textarea {
  -webkit-appearance: none;
  line-height: normal;
}

/* Color */
/* Box Shadow */
/* Border */
/* Filter */
/* Components */
/* @media */
/* Default Break */
/* Font Break */
/* Layout Break */
.noselection, button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  outline: none;
  border: var(--border-width) solid var(--button-border-color);
  border-radius: var(--border-radius-hard);
  background-color: var(--button-background-color);
  color: var(--button-color);
  padding: 1rem 2rem;
  font-size: 1.25rem;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
button img {
  height: 1rem;
}

button.selected, .button.selected {
  font-weight: 600 !important;
  color: var(--base-blue-color-lightened);
  border-color: var(--base-blue-color-lightened) !important;
}

.flex-column-wrap, .flex-column, .flex-row-wrap, .flex-row, .flex {
  display: flex;
}

.flex-row-wrap, .flex-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-column-wrap, .flex-column {
  flex-direction: column;
}

.flex-column-wrap, .flex-row-wrap {
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

* {
  font-family: "Roboto", serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
}

body * {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: var(--color-lightened-6);
  margin: 1.5rem 0;
  border-radius: var(--border-radius);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-lightened-3);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--color-lightened-3);
}

*::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  appearance: none;
}

html {
  font-size: 16px;
  -webkit-text-size-adjust: none;
}

html {
  --theme: "default";
  --base-background-color: #f8f9fa;
  --base-background-color-alert: #e2e4ff;
  --base-color-alert: #333333;
  --base-color-static: #000;
  --base-color: #000;
  --color-lightened-1: #4a5d75;
  --color-lightened-2: #98a0ab;
  --color-lightened-3: #b9bfc7;
  --color-lightened-4: #c1cdd5;
  --color-lightened-5: #dae1e6;
  --color-lightened-6: #e6eaef;
  --color-lightened-65: #f0f2f4;
  --color-lightened-7: #f8f9fa;
  --base-end-color-static: #fff;
  --base-end-color: #fff;
  --base-blue-color: #5963ff;
  --base-blue-color-lightened: #7178e3;
  --base-red-color: #eb4e39;
  --base-green-color: #86cf2d;
  --base-green-color-lightened: #3bd13b;
  --base-font-color: #333333;
  --base-font-color-secondary: #717171;
  --base-font-color-secondary-half: #555555;
  --base-box-shadow-color: #33333322;
  --base-box-shadow-color-dark: #3333;
  --base-box-shadow-color-darkest: #0003;
  --box-shadow: 0px 4px 12px var(--base-box-shadow-color);
  --box-shadow-top: 0 12px 6px -6px var(--base-box-shadow-color);
  --box-shadow-right: 12px 0 6px -6px var(--base-box-shadow-color);
  --box-shadow-bottom: 0 -12px 6px -6px var(--base-box-shadow-color);
  --box-shadow-left: -12px 0 6px -6px var(--base-box-shadow-color);
  --box-shadow-dark: 0px 6px 12px var(--base-box-shadow-color-dark);
  --box-shadow-darkest: 0px 6px 12px var(--base-box-shadow-color-darkest);
  --border-radius: 16px;
  --border-radius-hard: calc(var(--border-radius) * 2.5);
  --border-radius-hardest: calc(var(--border-radius) * 25);
  --border-color: #eff0f1;
  --border-color-dark-only: #00000000;
  --border-width: 0.1875em;
  --border-width-thin: calc((var(--border-width) / 4) * 3);
  --border-width-thick: calc(var(--border-width) * 2);
  --border-width-dark-only: 0rem;
  --base-filter-brightness-5: unset;
  --base-filter-contrast-1: unset;
  --base-filter-invert-1: unset;
  --base-filter-contrast-invert-00: unset;
  --base-filter-contrast-invert-01: unset;
  --base-filter-contrast-invert-10: unset;
  --base-filter-contrast-invert-11: unset;
  --layout-break-5: 43em;
  --compactness: 1;
  --is-mobile: 0;
  --header-background-color: var(--base-end-color);
  --modal-container-background-color: var(--color-lightened-7);
  --collectible-background-color: var(--base-end-color);
  --collectible-body-background-color: var(--color-lightened-6);
  --collectible-thumbnail-border-radius: calc(var(--border-radius) / 2);
  --balances-background-color: var(--base-end-color);
  --moonpay-iframe-filter: unset;
  --dropdown-icon-invert: invert(1);
  --send-button-border-color: var(--border-color);
  --send-button-background-color: transparent;
  --send-button-tez-border-color: var(--base-blue-color-lightened);
  --send-button-tez-background-color: var(--base-blue-color-lightened);
  --send-button-tez-icon-color: var(--base-end-color);
  --send-button-buy-border-color: var(--base-blue-color-lightened);
  --send-button-buy-background-color: inherit;
  --send-button-buy-icon-color: var(--base-blue-color-lightened);
  --button-background-color: var(--base-blue-color-lightened);
  --button-color: var(--base-end-color);
  --button-red-background-color: var(--base-red-color);
  --button-red-color: var(--base-end-color);
  --button-background-color: var(--base-blue-color-lightened);
  --button-color: var(--base-end-color);
  --button-red-background-color: var(--base-red-color);
  --button-red-color: var(--base-end-color);
  --button-border-color: transparent;
  --search-bar-background-color: #d0d8e1;
  --background-expected-color: #f2f3ff;
  --background-from-color: #f7f7f7;
  --lqd-font-secondary: #666666;
  --lqd-font-terinary: #858585;
  --lqd-chart-filler-background-color: #5963ff1a;
  --buy-background-color: #eaf1f3;
  --attr-label-color: #9f9f9f;
}
html.dark {
  --theme: "dark";
  --base-background-color: #1a1b1e;
  --base-background-color-alert: #e6e6e6;
  --base-color-alert: #333333;
  --base-color: #000;
  --color-lightened-1: #888b8d;
  --color-lightened-2: #787b7d;
  --color-lightened-3: #686b6d;
  --color-lightened-4: #585b5d;
  --color-lightened-5: #484b4d;
  --color-lightened-6: #383b3d;
  --color-lightened-65: #252526;
  --color-lightened-7: #252526;
  --border-color: #333337;
  --border-color-dark-only: #333337;
  --base-end-color: #1d1d21;
  --base-font-color: #c9d1d9;
  --base-font-color-secondary: rgba(255, 255, 255, 0.6);
  --base-font-color-secondary-half: rgba(255, 255, 255, 0.6);
  --border-width-dark-only: var(--border-width);
  --base-filter-brightness-5: brightness(5);
  --base-filter-contrast-0: contrast(0);
  --base-filter-contrast-1: contrast(1);
  --base-filter-invert-0: invert(0);
  --base-filter-invert-1: invert(1);
  --base-filter-contrast-invert-00: var(--base-filter-contrast-0) var(--base-filter-invert-0);
  --base-filter-contrast-invert-01: var(--base-filter-contrast-0) var(--base-filter-invert-1);
  --base-filter-contrast-invert-10: var(--base-filter-contrast-1) var(--base-filter-invert-0);
  --base-filter-contrast-invert-11: var(--base-filter-contrast-1) var(--base-filter-invert-1);
  /* Components */
  --header-background-color: #1d1d21;
  --collectible-background-color: #393940;
  --collectible-body-background-color: #26262b;
  --balances-background-color: #26262b;
  --modal-container-background-color: #26262b;
  --send-button-border-color: var(--border-color);
  --send-button-background-color: var(--border-color);
  --send-button-tez-icon-color: var(--border-color);
  --send-button-buy-border-color: var(--base-blue-color-lightened);
  --send-button-buy-background-color: inherit;
  --send-button-buy-icon-color: var(--base-blue-color-lightened);
  --collectible-thumbnail-border-radius: 0;
  --search-bar-background-color: #303036;
  --moonpay-iframe-filter: invert(0.925) hue-rotate(175deg);
  --dropdown-icon-invert: invert(0);
  --button-background-color: var(--base-end-color);
  --button-color: var(--base-blue-color-lightened);
  --button-red-background-color: var(--base-end-color);
  --button-red-color: var(--base-red-color);
  --button-border-color: var(--base-blue-color-lightened);
  --background-expected-color: #393940;
  --background-from-color: #26262b;
  --lqd-font-secondary: #999999;
  --lqd-font-terinary: #7a7a7a;
  --lqd-chart-filler-background-color: rgba(89, 99, 255, 0.114);
  --buy-background-color: #252526;
  --attr-label-color: #737373;
}

html,
body {
  background-color: var(--base-background-color);
  overflow: scroll;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

form {
  width: 100%;
  display: contents;
}

input {
  text-align: left;
  height: 4.5rem;
  padding: 1px 1px 1px 2em;
  box-sizing: border-box;
  border-radius: var(--border-radius-hard);
  background-color: var(--base-end-color);
}
input:focus {
  border-color: var(--base-blue-color) !important;
}
input:disabled {
  filter: opacity(0.85);
}
input::placeholder {
  color: var(--base-font-color);
}
input:autofill, input:autofill:hover, input:autofill:focus {
  box-shadow: 0 0 0 1000px var(--base-end-color) inset !important;
  color: var(--base-font-color) !important;
  -webkit-text-fill-color: var(--base-font-color) !important;
  background-clip: content-box !important;
  padding: 1px 1px 1px 2em !important;
  -webkit-appearance: none !important;
}

form {
  width: 100%;
  display: contents;
}

h1 {
  font-weight: 500;
}

textarea {
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background-color: var(--base-end-color);
}

br {
  height: 0;
  margin: 0.625rem 0;
}

.button {
  cursor: pointer;
}
button:hover {
  filter: saturate(1.5);
}

.block {
  display: block;
}

.inline {
  display: inline-block;
}

#cbpay-embedded-onramp {
  z-index: 1000;
}

@media (max-width: 575px) {
  #cbpay-embedded-onramp {
    top: 45px;
    height: calc(100% - 45px);
  }
}
@media (max-width: 253em) {
  html {
    font-size: 100%;
  }
}
@media (max-width: 153em) {
  html {
    font-size: 90%;
  }
}
@media (max-width: 123em) {
  html {
    font-size: 80%;
  }
}
@media (max-width: 103em) {
  html {
    font-size: 70%;
  }
}
@media (max-width: 83em) {
  html {
    font-size: 60%;
  }
}
@media (max-width: 63em) {
  html {
    font-size: 50%;
  }
}